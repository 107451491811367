import React from 'react';

import { 
    styled,
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    AppBar, CircularProgress
} from '@material-ui/core';
import { FaArrowLeft, FaGreaterThan, FaLessThan } from "react-icons/fa";
import { get } from '../commonTable/table';


import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';
import ConfirmationDrawer from '../ConfirmationDrawer';
import { timestampToTime } from '../addNewScreen';

export const configJSON = require("../config");

const styles = {
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
  fileNameTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
  buttonArea: {
    bottom: '18px',
    width: '100%',
    maxWidth: 'calc(100% - 40px)',
  },
  mediaView: {
    zIndex: 9,
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    paddingTop: '100px',
  },
  circularProgressDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
};

export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

class ManagerAdDetails extends BlockComponent<Props, any, SS> {
  getAllAdDataApiID: string = '';
  removeAdApiID: string = '';
  getSingleAdDataApiID: string = '';

  constructor(props: any) {
    super(props);

		this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      modalAdd: false,
			fileDetailData: [],
      viewAd: false,
      errorMessage: '',
      screenUrlCopy:false,
      duration: null,
      removeData: false,
      removeAdLoader: false,
      current: 0,
      isImageLoading:false,
      isVideoLoading: false,
      imageSrc:""
    };
  }

	async componentDidMount() {
    super.componentDidMount();

		this.getAllAdDetails();
    this.getSingleAdDetails()
	}

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: SS | undefined) {
    if(prevState.fileDetailData !== this.state.fileDetailData){
      const firstFile = this.getFieldValue("file"); 
      this.setState({ isImageLoading: true,isVideoLoading:true, imageSrc: "" }, () => {
        setTimeout(() => {
          this.setState({ imageSrc: firstFile });
        }, 100);
      });
    }
  }
	apiCall = async ({
		method, endPoint, contentType, body, isFormData
	}: any) => {
		const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isFormData ? body : JSON.stringify(body)
    );

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return requestMessage;
	}

	getAllAdDetails = async () => {
    const screenId = this.props.navigation.getParam('screenId');
    if(!screenId){
      return
    }
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpPostMethod, 
			endPoint: `${configJSON.viewAllAdApiEndPoint}`, 
			contentType: "application/json",
      body: {
        "screen_id": screenId
      },
      isFormData: false
		})

		this.getAllAdDataApiID = GetRegionAPI.messageId;
	}

  getSingleAdDetails = async () => {
    const adId = this.props.navigation.getParam('adId');
    if(!adId){
      return
    }
		const GetSingleAdAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.getAdDetailsApiEndPoint}${adId}`,
			contentType: "application/json",
		})

		this.getSingleAdDataApiID = GetSingleAdAPI.messageId;
	}

  getFieldValue = (keyValue: string): string => {
    return get(this.state.fileDetailData[this.state.current], `attributes.${keyValue}`) || '-'
  }


	goBack = () => {
		this.props.navigation.goBack()
	}

  goToScreen = () => {
		this.props.navigation.navigate('LocalListingAds')
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
		const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

		if(apiRequestCallId == this.getAllAdDataApiID){ 
      if(!responseJson.errors && responseJson.data) this.setState({fileDetailData: responseJson.data.reverse()});
      else {this.setState({fileDetailData: []})}
    } else if(apiRequestCallId == this.getSingleAdDataApiID){
      if(!responseJson.errors && responseJson.file) this.setState({fileDetailData: [responseJson.file.data]});
      else {this.setState({fileDetailData: []})}
    } else if(apiRequestCallId == this.removeAdApiID){ 
      if(!responseJson.errors) {
        this.setState({removeAdLoader: false});
        this.handleRemoveData();
        setStorageData('alert', JSON.stringify({
          message: 'Ad has been successfully removed'
        }))
        this.goToScreen();
      }
    }
	}

  updateVideoDetails = () => {
    if(this.getFieldValue('media_format') === 'image') {
      this.setState({duration: timestampToTime(this.getFieldValue('duration'))})
    } else {
      const videoElement = document.getElementById("previewVideo") as HTMLVideoElement;
      if (videoElement) {
          const blob = new Blob([videoElement.src], { type: this.getFieldValue("media_format") });
          const reader = new FileReader();

          reader.onload = (event) => {
            this.setState({
              duration: `${Math.floor(videoElement.duration)} seconds`,
            });
          };
          reader.readAsArrayBuffer(blob); 
      }
    }
  };

  handleRemoveData = () => {
    this.setState({removeData: !this.state.removeData})
  }

  handleSuccess = async () => {
    this.setState({removeAdLoader: true})
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpPostMethod, 
			endPoint: `${configJSON.removeAdApiEndPoint}`, 
			contentType: "application/json",
      body: {
        "screen_file_id": this.getFieldValue('id')
      },
      isFormData: false
		})

		this.removeAdApiID = GetRegionAPI.messageId;
  }

  handleNext = () => {
    this.setState({ isImageLoading: true, isVideoLoading: true, imageSrc: "" }, () => {
      this.setState((prevState: any) => {
        const nextIndex = (prevState.current + 1) % prevState.fileDetailData.length;
        return { current: nextIndex };
      }, () => {
        const mediaFormat = this.getFieldValue("media_format");
  
        if (mediaFormat === "Image") {
          this.setState({ imageSrc: this.getFieldValue("file") });
        } else {
          this.resetVideo();
        }
      });
    });
  };
  
  handlePrevious = () => {
    this.setState({ isImageLoading: true, isVideoLoading: true, imageSrc: "" }, () => {
      this.setState((prevState: any) => {
        const prevIndex =
          prevState.current === 0
            ? prevState.fileDetailData.length - 1
            : prevState.current - 1;
        return { current: prevIndex };
      }, () => {
        const mediaFormat = this.getFieldValue("media_format");
  
        if (mediaFormat === "Image") {
          this.setState({ imageSrc: this.getFieldValue("file") });
        } else {
          this.resetVideo();
        }
      });
    });
  };

  resetVideo = () => {
    this.setState({ isVideoLoading: true }, () => {
      const videoElement = document.getElementById("previewVideo") as HTMLVideoElement;
      if (videoElement) {
        videoElement.pause();
        videoElement.load();
      }
    });
  };
  

  render() {

    return (
      <>
        <StyledHeader>
          <Box style={{
            borderBottom: "1px solid #64748B",
            padding: '0 0 20px 16px',
            margin: '0 -16px'
          }}>
            <FaArrowLeft style={{ color: '#FFF' }} data-test-id="go-back" onClick={this.goBack} />
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 style={styles.headerTitle}>View Ad</h1>
            {this.state.fileDetailData.length > 1 && (
              <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <button
                  onClick={this.handlePrevious}
                  disabled={this.state.current === 0}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: this.state.current === 0 ? 'not-allowed' : 'pointer',
                    opacity: this.state.current === 0 ? 0.5 : 1,
                  }}
                >
                  <FaLessThan style={{ color: '#FFF' }} />
                </button>
                <span style={{ color: '#FFF', fontSize: '16px', fontWeight: 600 }}>
                  {`${this.state.current + 1} / ${this.state.fileDetailData.length}`}
                </span>
                <button
                  onClick={this.handleNext}
                  disabled={this.state.current === this.state.fileDetailData.length - 1}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: this.state.current === this.state.fileDetailData.length - 1 ? 'not-allowed' : 'pointer',
                    opacity: this.state.current === this.state.fileDetailData.length - 1 ? 0.5 : 1,
                  }}
                >
                  <FaGreaterThan style={{ color: '#FFF' }} />
                </button>
              </Box>
            )}
          </Box>
        </StyledHeader>
        {this.state.fileDetailData.length>0 ? 
        <>
          <Box style={{...styles.mediaView}}>
            {this.getFieldValue("file_name") && this.getFieldValue("file") ?
              <>
                {this.getFieldValue("media_format").toLowerCase().includes('video') ? 
                  <>
                  {this.state.isVideoLoading && (
                    <div style={{ display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50%' }}>
                      <CircularProgress style={{ color: "grey" }} />
                    </div>
                  )}<video
                    id="previewVideo"
                    controls
                    playsInline
                    loop
                    autoPlay
                    className="video-player"
                    style={{width:'100%', objectFit:'cover', opacity: 0, display: this.state.isVideoLoading ? "none" : "block",}}
                    onLoadedData={(event) => {
                      const videoElement = event.currentTarget;
                      videoElement.style.opacity = '1'; 
                      this.setState({ isVideoLoading: false }); 
                      videoElement.style.display = "block";
                    }}
                    onLoadedMetadata={this.updateVideoDetails}
                  >
                    <source src={this.getFieldValue("file")} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video></>
                  :
                  <>
                  {this.state.isImageLoading && (
                    <div style={{ display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '50%' }}>
                      <CircularProgress style={{ color: "grey" }} />
                    </div>
                  )}
                  <img src={this.state.imageSrc} alt={this.getFieldValue("file_name")} onLoadedMetadata={this.updateVideoDetails} style={{width:'100%', objectFit:'cover',display: this.state.isImageLoading ? "none" : "block", background: 'transparent', opacity: 0 }}
                  onLoad={(event) => {
                    const img = event.currentTarget as HTMLImageElement;
                    img.style.opacity = '1';
                    this.setState({ isImageLoading: false })
                  }}/></>
                }
              </>
            : null}
          </Box>
          <DetailedScreenBox>
            <h1 style={styles.fileNameTitle}>{this.getFieldValue("file_name")}</h1>
            <List className='list-items'>
              <ListItem style={{marginLeft: 0}}>
                <ListItemText 
                  id="media_format"
                  primary={"Media Format"} 
                  secondary={this.getFieldValue('media_format')} 
                />
              </ListItem>
              <ListItem style={{marginLeft: 0}}>
                <ListItemText 
                  primary={"File Size"} 
                  id="start_date"
                  secondary={this.getFieldValue("file_size")} 
                />
              </ListItem>
              {this.state.duration && <ListItem style={{marginLeft: 0}}>
                <ListItemText 
                  primary={"Duration"} 
                  id="duration"
                  secondary={`${this.state.duration}`} 
                />
              </ListItem>  || null}
              {(!this.state.duration && this.getFieldValue('duration') !== '') ? <ListItem style={{marginLeft: 0}}>
                <ListItemText 
                  primary={"Duration"} 
                  id="duration"
                  secondary={timestampToTime(this.getFieldValue('duration'))} 
                />
              </ListItem> : null}
            </List>
            <Box
              sx={{ position: 'fixed', 
                boxSizing: 'border-box',
              }} 
              style={styles.buttonArea}
              >
              <CancelButton
                fullWidth
                variant="text"
                onClick={this.goBack}
              >
                Cancel
              </CancelButton>
              <GoBackToScreenButton fullWidth onClick={this.handleRemoveData}>
                Remove
              </GoBackToScreenButton>
            </Box>
          </DetailedScreenBox>
        </> : <div style={styles.circularProgressDiv}>
                      <CircularProgress style={{ color: 'grey' }} />
                    </div>
        }

        <ConfirmationDrawer 
          title={`Are you sure you want to remove the file ${this.getFieldValue("file_name")} ?`}
          activeButtonText={"Confirm"}
          navigation={this.props.navigation} 
          open={this.state.removeData}
          handleOpen={this.handleRemoveData}
          handleCancel={this.handleRemoveData}
          handleSuccess={this.handleSuccess}
          activeButtonLoader={this.state.removeAdLoader}
        />
      </>
    );
  }
}

export default ManagerAdDetails;

const DetailedScreenBox = styled(Box)({
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  padding: "16px",
  paddingTop: "8px",
  paddingBottom: "160px",
  height: 'fit-content',

  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const StyledHeader = styled(AppBar)({
  boxShadow: "none",
  zIndex: 999,
  padding: "20px 16px",
  paddingBottom: '0px',
  color: "#FFF",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'
});

const CancelButton = styled(Button)({
  padding: '12px 0px',
  borderRadius: '8px',
  color: "#ffffff",
  background: "#000",
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'none',
  marginBottom: '8px',
})

const GoBackToScreenButton = styled(Button)({
  padding: '12px 0px',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'none',
})