
import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  InputLabel,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";
import { TransitionProps } from '@material-ui/core/transitions';
import { Formik } from "formik";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        textTransform: 'none',
        fontSize: '0.8em',
        margin: '0',
        '&.backArrowBtn': {
          paddingLeft: '0px !important',
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 700,
        color: "#F1F5F9",
        marginBottom: "4px"
      }
    },
    MuiOutlinedInput: {
      root: {
        // border: "none",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        "&:focus": {
          border: "1px solid #CBD5E1",
        },
        "&:hover $notchedOutline": {
          border: "none", // Ensure border color remains visible on hover
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        }
      },
      input: {
        background: "transparent",
        fontSize: "16px",
        color: "#94A3B8",
        fontWeight: 400
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 700,
        color: "#F1F5F9",
        marginBottom: "4px"
      }
    },
    MuiInputBase: {
      input: {
        border: "none",
        background: "transparent",
        borderRadius: "8px",
        fontSize: "16px",
        color: "#94A3B8",
        fontWeight: 400
      },
    },
  },
});
// import './CardList.css';
// Customizable Area End

import CardDetailsController, {
  Props
} from "./CardDetailsController";
import { ArrowBack, ChevronRight } from "@material-ui/icons";
import { master_card_icon, delete_icon, visa_card_icon, edit_light_icon, success_icon } from "./assets";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class CardList extends CardDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  addNewPayment() {
    return (
      <Box>
        <div style={{ paddingTop: '28px' }}>
          <Typography variant="body1" style={webStyle.enterCardDetails}>ENTER CARD DETAILS</Typography>
        </div>
        <Box sx={{ padding: '12px 24px' }}>
          <Formik
            initialValues={{ cardIconSrc: "", cardNumber: this.state.cardNumber, cardHolderName: this.state.cardHolderName, expiryDate: this.state.expiryDate, cvv: this.state.cvv }}
            onSubmit={() => {
            }}
            data-test-id="formik_form"
          >
            {({ setFieldValue }) => (
              <form data-test-id="submit_form" onSubmit={this.handleSubmit} noValidate autoComplete="off">
                <Grid item xs={12} style={webStyle.inputWrapper}>
                  <InputLabel>
                    Card number
                  </InputLabel>
                  <TextField
                    data-test-id="cardNumber"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle visibility"
                            edge="end"
                          >
                            <img className="cardIcon" src={this.getCardIconSrc()} />
                          </IconButton>
                        </InputAdornment>
                    }}
                    placeholder="0000 0000 0000 0000"
                    value={this.state.cardNumber}
                    onChange={(event: any) => {
                      this.handleCardNumber(event);
                      setFieldValue("cardNumber", this.state.cardNumber);
                      this.addButtonEnableDisable()
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={webStyle.inputWrapper}>
                  <InputLabel>
                    Cardholder name
                  </InputLabel>
                  <TextField
                    data-test-id="cardHolderName"
                    fullWidth
                    variant="outlined"
                    placeholder="Cardholder name"
                    autoComplete="off"
                    value={this.state.cardHolderName}
                    onChange={(event: any) => {
                      this.handleCardHolderName(event);
                      setFieldValue("cardHolderName", this.state.cardHolderName);
                      this.addButtonEnableDisable()
                    }}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} lg={6} xl={6} style={webStyle.inputWrapper}>
                    <InputLabel>
                      Expiry Date
                    </InputLabel>
                    <TextField
                      data-test-id="expiryDate"
                      fullWidth
                      variant="outlined"
                      placeholder="MM/YYYY"
                      autoComplete="off"
                      value={this.state.expiryDate}
                      onChange={(event: any) => {
                        this.handleExpiryDate(event);
                        setFieldValue("expiryDate", this.state.expiryDate);
                        this.addButtonEnableDisable()
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} xl={6} style={webStyle.inputWrapper}>
                    <InputLabel>
                      CVV
                    </InputLabel>
                    <TextField
                      data-test-id="cvv"
                      fullWidth
                      variant="outlined"
                      placeholder="3 - 4 digits"
                      autoComplete="off"
                      value={this.state.cvv}
                      onChange={(event: any) => {
                        this.handleCvv(event);
                        setFieldValue("cvv", this.state.cvv);
                        this.addButtonEnableDisable()
                      }}
                    />
                  </Grid>
                </Grid>
                {this.state.incorrectCardNumber ?
                  <Box sx={webStyle.errorBox} data-test-id="btnInvalidMethod">
                    Invalid card. Please check details to proceed.
                  </Box> :
                  ""}
                {this.state.serverError ?
                  <Box sx={webStyle.errorBox} data-test-id="btnServerError">
                    Server error occurred
                  </Box> :
                  ""}
                <button
                  ref={this.submitRef}
                  data-test-id="btnAddPaymentMethod"
                  type="submit"
                  disabled={!this.state.isAllFieldFill}
                  style={{
                    ...webStyle.addPaymentBtn,
                    color: "#000000",
                    background: !this.state.isAllFieldFill && "linear-gradient(174.53deg, rgba(255, 255, 255, 0.4) 4.52%, rgba(145, 145, 145, 0.4) 103.95%)" || "",
                  }}
                >
                  {this.state.cardDetailsProps.id ? "Save" : "Add payment method"}
                </button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    )
  }

  editNewPayment() {
    return (
      <Box>
        <ListItem button data-test-id='btn' style={{ padding: '8 16', borderBottom: '1px solid #64748B' }}>
          <ListItemIcon style={{ marginRight: '8px', minWidth: 'initial' }}>
            <img data-test-id="card-icon" src={this.state.cardDetailsProps?.attributes?.card?.brand === "visa" ? visa_card_icon : master_card_icon} />
          </ListItemIcon>
          <ListItemText key='text' style={{ fontSize: '14px', fontWeight: 400, color: '#CBD5E1' }} >
            <span data-test-id="last-digit" style={{ padding: '0' }}>**** {this.state.cardDetailsProps?.attributes?.card?.last4} </span><br />
          </ListItemText>
        </ListItem>

        <ListItem button data-test-id='btn' style={{ padding: '8 16', borderBottom: '1px solid #64748B' }}>
          <ListItemIcon style={{ marginRight: '8px', minWidth: 'initial' }}>
            <img src={edit_light_icon} />
          </ListItemIcon>
          <ListItemText key='text' style={{ fontSize: '16px', fontWeight: 500, lineHeight: '24px', color: '#FFFFFF' }}>
            <span
              data-test-id="bind details"
              style={{ padding: '0' }}
              onClick={() =>
                this.setState({
                  addPaymentMethod: "ADD_NEW_PAYMENT",
                  cardNumber: "",
                  cvv: "",
                  cardHolderName: this.state.cardDetailsProps?.attributes?.metadata?.name,
                  expiryDate: `${this.state.cardDetailsProps?.attributes?.card?.exp_month}/${this.state.cardDetailsProps?.attributes?.card?.exp_year}`,
                  isEditPaymentPageTitle: true,
                  isAllFieldFill: false,
                  isEditInputField: false
                })}>
              Edit
            </span><br />
          </ListItemText>
        </ListItem>

        <ListItem button data-test-id='btn' style={{ padding: '8 16', borderBottom: '1px solid #64748B' }} onClick={() => this.confirmPopUp()}>
          <ListItemIcon style={{ marginRight: '8px', minWidth: 'initial' }}>
            <img src={delete_icon} />
          </ListItemIcon>
          <ListItemText key='text' style={{ fontSize: '16px', fontWeight: 500, lineHeight: '24px', color: '#DC2626' }} data-test-id="delete card">
            <span data-test-id="delete card id" style={{ padding: '0' }}>Delete payment method</span><br />
          </ListItemText>
        </ListItem>
        {this.state.isUpdateMessageStatus &&
          <Box sx={webStyle.successStatusWrapper} data-test-id="card_success">
            <Box sx={webStyle.successStatus}>
              <img src={success_icon} /> Card updated successfully
            </Box>
          </Box>
        }
      </Box>
    )
  }
  // Customizable Area End


  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.bankCardWrapper}>
        <ThemeProvider theme={theme}>
          <Grid container style={{ height: "100%", position: "relative" }}>

            <Grid item xs={12} style={{ overflow: 'auto', height: '100%', paddingBottom: "50px" }}>
              <Box style={{ padding: '12px' }}>
                <IconButton
                  data-test-id="backArrowBtn"
                  onClick={() => {
                    this.handleClickBack()
                  }}
                >
                  <ArrowBack style={{ height: '24px', width: '24px', color: '#fff' }} />
                </IconButton>
              </Box>
              <Typography variant="body1" style={webStyle.cardTitleTxt} >{this.state.isEditPaymentPageTitle ? 'Edit Card details' : 'Card details'}</Typography>
              {
                this.state.addPaymentMethod === "" && (
                  <>
                    <Typography variant="body2" style={{
                      fontSize: "14px", fontWeight: 700, lineHeight: "22px",
                      color: "#FFFFFF",
                      padding: "24px 24px 8px 24px",
                      borderBottom: "1px solid #64748B",
                      textTransform: "uppercase"
                    }}>select a card</Typography>
                    { this.state.cardDetails.length > 0 ? (
                      
                      Array.isArray(this.state.cardDetails) && this.state.cardDetails.map((card: any, index: number) => {
                        return (
                          <ListItem
                            key={`${card.id}${index}`}
                            button
                            style={{ padding: '8 16', borderBottom: '1px solid #64748B' }}
                            data-test-id="edit & delete"
                            onClick={() => this.setState({ addPaymentMethod: 'EDIT_PAYMENT_METHOD', cardDetailsProps: card, isEditInputField: false, isAllFieldFill: false })}
                          >
                            <ListItemIcon style={{ marginRight: '8px', minWidth: 'initial' }} data-test-id='card_icon'>
                              <img src={card?.attributes?.card?.display_brand === "visa" ? visa_card_icon : master_card_icon} />
                            </ListItemIcon>
                            <ListItemText style={{ fontSize: '14px', fontWeight: 400, color: '#ffffff' }} data-test-id='card_number'>
                              <span style={{ padding: '0' }}>**** {card?.attributes?.card?.last4}</span><br />
                            </ListItemText>
                            <ListItemIcon key={`${card.id}${index}icon`} style={{ minWidth: '10px', color: '#CBD5E1' }}><ChevronRight /></ListItemIcon>
                          </ListItem>
                        );
                      })
                    ) : Array.isArray(this.state.cardDetails) && 
                    !this.state.cardDetails.length && 
                    !this.state.isLoading && 
                    !this.state.isLoadingCardDetails && (
                      <Box sx={webStyle.cardErrorBox} data-test-id="noCardsAvailable"> No cards are available. Please click on add payment method</Box>
                    )}
                    {this.state.isLoading && (
                      <Box style={webStyle.loaderWrapper} position={"fixed"}>
                        <CircularProgress />
                      </Box>
                    )}

                    {Array.isArray(this.state.cardDetails) && this.state.cardDetails.length < 8 ?
                      (
                        <ListItem key={`addcardbutton`} button data-test-id={`addCardBtn`} style={{ padding: '8 16', borderBottom: '1px solid #64748B' }}>
                          <ListItemText
                            style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#fff' }}
                            onClick={() =>
                              this.setState({
                                addPaymentMethod: 'ADD_NEW_PAYMENT',
                                isEditInputField: true,
                                cardDetailsProps: {},
                                cardNumber: '',
                                cardHolderName: '',
                                cvv: '',
                                expiryDate: '',
                                isEditPaymentPageTitle: false,
                                isAllFieldFill: false
                              })}>

                            <span style={{ padding: '0' }}>Add payment method</span>
                            <br />
                          </ListItemText>
                          <ListItemIcon style={{ minWidth: '10px', color: '#CBD5E1' }}><ChevronRight /></ListItemIcon>
                        </ListItem>
                      ) : this.state.cardDetails.length >= 8 && !this.state.isLoading ? (
                        <Box sx={webStyle.cardErrorBox} data-test-id="btnInvalidMethod">
                          Your card limit is full. You cannot add more than 8 cards
                        </Box>
                      ) : null
                    }

                    <Box sx={webStyle.successStatusWrapper} data-test-id="card_success">
                      {[
                        { condition: this.state.isDeleteStatus, message: "Card deleted successfully" },
                        { condition: this.state.postPaymentStatus, message: "Card successfully added" },
                        { condition: this.state.isUpdateMessageStatus, message: "Card updated successfully" },
                      ].map(({ condition, message }, index) => (
                        condition && (
                          <Box key={index} sx={webStyle.successStatus}>
                            <img src={success_icon} /> {message}
                          </Box>
                        )
                      ))}
                    </Box>
                  </>
                )
              }
              {this.state.addPaymentMethod === "ADD_NEW_PAYMENT" && this.addNewPayment()}
              {this.state.addPaymentMethod === "EDIT_PAYMENT_METHOD" && this.editNewPayment()}
            </Grid>
          </Grid>
        </ThemeProvider>
        {/* delete confirmation popup */}
        <Dialog
          open={this.state.openModalPopup}
          TransitionComponent={Transition as any}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: webStyle.dialogWrapper }}
        >
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-slide-description" align="center" style={webStyle.dialogContentWrapper}>
              Are you sure want to delete card details?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={webStyle.deleteActionWrapper}>
            <Button style={webStyle.noButton} onClick={this.handleClose}>Cancel</Button>
            <Button style={webStyle.yesButton} onClick={this.deletecard}>Yes</Button>
          </DialogActions>
        </Dialog>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  bankCardWrapper: {
    background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
    height: "100vh",
    overflowY: 'auto',
    fontFamily: "Inter, sans-serif"
  },
  cardTitleTxt: {
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#FFFFFF",
    padding: "8px 24px 14px 24px",
    borderBottom: "1px solid #64748B"
  },
  enterCardDetails: {
    padding: "16px 24px 8px",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    borderBottom: "1px solid #64748B",
    color: "#F8FAFC"
  },
  inputWrapper: {
    marginBottom: "16px",
    '&.border-bottom': {
      borderBottom: "1px solid #E2E8F0"
    }
  },
  addPaymentBtn: {
    background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)",
    padding: "16px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    width: "100%",
    margin: "40px 0",
    borderRadius: "8px",
    border: "none",
    color: "#000000",
    '&:disabled': {
      background: "linear-gradient(174.53deg, rgba(255, 255, 255, 0.4) 4.52%, rgba(145, 145, 145, 0.4) 103.95%)"
    }
  },
  errorBox: {
    borderLeft: "4px solid #DC2626",
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    padding: "12px 16px",
    marginTop: "40px",
    position: 'fixed',
    top: '75vh',
    width: 'calc(100vw - 80px)',
    left: '25px',
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    position: 'fixed',
    bottom: "20px",
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#fff",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
  },
  successStatusWrapper: {
    position: "absolute",
    bottom: "50px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  cardErrorBox: {
    borderLeft: "4px solid #DC2626",
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    padding: "15px 16px",
    margin: "15px 0",
    // position: 'fixed',
    // top: '60vh',
    width: '100%',
    boxSizing: "border-box",
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    top: 0,
    zIndex: 9999,
    width: "100%",
  },
  dialogWrapper: {
    borderRadius: "16px"
  },
  dialogContentWrapper: {
    fontSize: "20px",
    color: "#000"
  },
  deleteActionWrapper: {
    justifyContent: "space-between",
    padding: 0,
  },
  yesButton: {
    margin: 0,
    padding: "12px",
    width: "50%"
  },
  noButton: {
    margin: 0,
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    width: "50%",
    padding: "12px",
    borderRadius: "unset"
  }
};

// Customizable Area End


