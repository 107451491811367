// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3.web";

import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import RecoveryMailSuccess from "../../blocks/forgot-password/src/RecoveryMailSuccess";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import AdManager from "../../blocks/admanager/src/AdManager";
import Videos from "../../blocks/videos/src/Videos";
import Inapppurchasing1 from "../../blocks/inapppurchasing1/src/Inapppurchasing1";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import HomeDashboard from "../../blocks/signuplogin/src/HomeDashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import PrivacyPolicy from "../../blocks/termsandconditions/src/PrivacyPolicy";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Splashscreen1 from "../../blocks/splashscreen1/src/Splashscreen1";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Settings from "../../blocks/settings/src/Settings";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import RSSFeeds from "../../blocks/rssfeeds/src/RSSFeeds";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Qrcodegeneration10 from "../../blocks/qrcodegeneration10/src/Qrcodegeneration10";
import Cfaifiltering1 from "../../blocks/cfaifiltering1/src/Cfaifiltering1";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import ChangePassword from "../../blocks/settings/src/ChangePassword";
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web'
import AddNewCard from "../../blocks/stripepayments/src/AddNewCard.web";
import CardList from "../../blocks/stripepayments/src/CardList.web";
import PaymentScreen from "../../blocks/uploadmedia3/src/PaymentScreen.web";
import PaymentSuccess from "../../components/src/PaymentSuccess.web";
import Preview from "../../blocks/uploadmedia3/src/Preview.web";
import QRCodeScanner from "../../blocks/signuplogin/src/QRCodeScanner.web"
import { getToken } from "../../blocks/utilities/src/RegExRequired";
import LocalListingAds from "../../blocks/locallistingads/src/LocalListingAds.web";
import LocalListingFiles from "../../blocks/locallistingads/src/LocalListingFiles.web";
import ManagerHelp from "../../components/src/screenManager/ManagerHelp.web";
import ManagerSetting from "../../components/src/screenManager/ManagerSetting.web";
import ManagerChangePassword from "../../components/src/screenManager/ManagerChangePassword.web";
import CreateScreen from "../../components/src/addNewScreen";
import ManagerScreenDetail from "../../components/src/screenManager/ManagerScreenDetails";
import ManagerAdDetail from "../../components/src/screenManager/ManagerAdDetails";
import ManagerViewAd from "../../components/src/screenManager/ManagerViewAd";
import ManagerSearch from "../../components/src/screenManager/ManagerSearch";
import ManagerAdList from '../../components/src/screenManager/ManagerAdList';
import Cfonplatformscreencreation0 from "../../blocks/cfonplatformscreencreation0/src/Cfonplatformscreencreation0.web";
import ManagerUsers from "../../../packages/components/src/screenManager/ManagerUsers"
import ManagerUserDetail from '../../../packages/components/src/screenManager/ManagerUserDetail'
import Notification from '../../../packages/components/src/screenManager/ManagerNotifications'
import { Dialog, Box, Typography, List, ListItem, ListItemText } from '@material-ui/core';

const routeMap = {
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Qrcodegeneration10: {
    component: Qrcodegeneration10,
    path: "/Qrcodegeneration10"
  },
  Cfaifiltering1: {
    component: Cfaifiltering1,
    path: "/Cfaifiltering1"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },

  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Adminconsole: {
    component: Adminconsole,
    path: "/Adminconsole"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  QRCodeScanner: {
    component: QRCodeScanner,
    path: "/QRCodeScanner"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  RecoveryMailSuccess: {
    component: RecoveryMailSuccess,
    path: "/RecoveryMailSuccess"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  AdManager: {
    component: AdManager,
    path: "/AdManager"
  },
  ManagerHelp: {
    component: ManagerHelp,
    path: "/ManagerHelp"
  },
  ManagerSetting: {
    component: ManagerSetting,
    path: "/ManagerSetting"
  },
  ManagerChangePassword: {
    component: ManagerChangePassword,
    path: "/ManagerChangePassword"
  },
  ManagerChangeEmail: {
    component: ManagerSetting,
    path: "/ManagerChangeEmail"
  },
  ManagerProfile: {
    component: ManagerSetting,
    path: "/ManagerProfile"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  Inapppurchasing1: {
    component: Inapppurchasing1,
    path: "/Inapppurchasing1"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Signuplogin: {
    component: Signuplogin,
    path: "/Signuplogin"
  },
  SignuploginBlockUploadScreen: {
    component: Signuplogin,
    path: "/Signuplogin/:screenid"
  },
  HomeDashboard: {
    component: HomeDashboard,
    path: "/HomeDashboard"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  CardList: {
    component: CardList,
    path: "/CardList"
  },
  Termsandconditions: {
    component: Termsandconditions,
    path: "/Termsandconditions"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  QrCodes: {
    component: QrCodes,
    path: "/QrCodes"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Splashscreen1: {
    component: Splashscreen1,
    path: "/",
    exact: true
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  Settings: {
    component: Settings,
    path: "/Settings"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  EmailAccountLoginBlockUploadScreen: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock/:screenid"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  RSSFeeds: {
    component: RSSFeeds,
    path: "/RSSFeeds"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Home: {
    component: HomeScreen,
    path: '/Home',

  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  AddNewCard: {
    component: AddNewCard,
    path: "/AddNewCard"
  },
  PaymentScreen: {
    component: PaymentScreen,
    path: "/PaymentScreen",
  },
  EditScreen: {
    component: CreateScreen,
    path: "/EditScreen/:screenId"
  },
  PaymentScreenQr: {
    component: PaymentScreen,
    path: "/PaymentScreen/:screenid",
  },
  PaymentPreview: {
    component: PaymentScreen,
    path: "/PaymentPreview"
  },
  PaymentSuccess: {
    component: PaymentSuccess,
    path: "/PaymentSuccess"
  },
  PaymentFailed: {
    component: PaymentSuccess,
    path: "/PaymentFailed"
  },
  Preview: {
    component: Preview,
    path: "/Preview"
  },
  LocalListingAds: {
    component: LocalListingAds,
    path: "/LocalListingAds"
  },
  LocalListingFiles: {
    component: LocalListingFiles,
    path: "/LocalListingFiles"
  },
  CreateScreen: {
    component: CreateScreen,
    path: "/CreateScreen"
  },
  ManagerScreenDetail: {
    component: ManagerScreenDetail,
    path: "/ManagerScreenDetail/:screenId"
  },
  ManagerAdDetail: {
    component: ManagerAdDetail,
    path: "/ManagerAdDetail/:adId"
  },
  ManagerUserDetail: {
    component: ManagerUserDetail,
    path: "/ManagerUserDetail/:userId"
  },
  ViewAd: {
    component: ManagerViewAd,
    path: "/ViewAd/:adId"
  },
  ViewAllAd: {
    component: ManagerViewAd,
    path: "/ViewAllAd/:screenId"
  },
  AdList: {
    component: ManagerAdList,
    path: "/AdList/:userId/:userName"
  },
  displayScreen: {
    component: Cfonplatformscreencreation0,
    path: "/displayScreen/:uniqueid",
  },
  ManagerSearch: {
    component: ManagerSearch,
    path: '/ManagerSearch'
  },
  users: {
    component: ManagerUsers,
    path: "/users",
  },
  notifications: {
    component: Notification,
    path: "/notifications"
  },
  notificationDetail: {
    component: Notification,
    path: "/notification/:notificationId"
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirecting: true,
      showPopup: false,
      isLandscape: false, 
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkOrientation();
    window.addEventListener("resize", this.checkOrientation);

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent("APP_Loaded");

    const isLoggedIn = getToken();
    const authRoutes = ["/ForgotPassword", "/SignUpLogin", "/EmailAccountLoginBlock", "/NewPassword"];
    const currentRoute = document.location.pathname;

    if (isLoggedIn) {
      if (authRoutes.includes(currentRoute)) {
        document.location.pathname = "/HomeDashboard";
      } else {
        this.setState({ isRedirecting: false });
      }
    } else {
      if (currentRoute.includes("PaymentScreen")) {
        document.location.pathname = "/EmailAccountLoginBlock/" + currentRoute.split("/")[2];
      } else if (currentRoute.includes("displayScreen")) {
        this.setState({ isRedirecting: false });
      } else if (!authRoutes.includes(currentRoute) && !currentRoute.split("/")[2]) {
        document.location.pathname = "/EmailAccountLoginBlock";
      } else {
        this.setState({ isRedirecting: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkOrientation);
  }

  checkOrientation = () => {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    this.setState((prevState) => {
      if (prevState.isLandscape !== isLandscape) {
        return {
          isLandscape,
          showPopup: isLandscape, 
        };
      }
      return null; 
    });
  };

  togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  };

  renderPopup() {
    const { showPopup } = this.state;
    if (!showPopup) return null;
    return (
      <Dialog open={showPopup}>
        <Box
          sx={{
            backgroundColor: "#1e1e1e",
            color: "#ffffff",
            padding: 2,
            borderRadius: 2,
            maxWidth: 500,
            margin: "auto",
            textAlign: "left",
            padding: '30px'
          }}
        >
          <Typography style={{fontWeight: 900}} gutterBottom>
            Orientation Locked
          </Typography>
          <Typography style={{fontSize: '13px'}} gutterBottom>
            This application is best experienced in portrait mode. Please rotate your
            device to portrait mode and adjust your settings if needed.
          </Typography>
          <Typography style={{marginTop: '10px', fontWeight: 800}} gutterBottom>
            How to Adjust:
          </Typography>
          <ul style={{fontSize: '13px'}}>
            <li style={{lineHeight: '30px'}}>For iOS: Swipe down to open the Control Center and disable Rotation Lock.</li>
            <li>For Android: Swipe down to access Quick Settings and disable Auto-Rotate.</li>
          </ul>
          <Typography>
            The app will resume once the device is set to portrait mode.
          </Typography>
        </Box>
      </Dialog>
    );
  }

  render() {
    const { isRedirecting, showPopup } = this.state;
    const token = getToken()
    const userData = JSON.parse(localStorage.getItem("userData"));
    const displayScreenPath = document.location.pathname.includes('displayScreen/')
    const width = ((window.innerWidth < 1000 && window.innerHeight < 650) || (window.innerWidth < 650 && window.innerHeight < 1000))

    if (isRedirecting) {
      return null;
    }

    return (
      <div style={{ width: "100vw", background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)' }}>
        {
          showPopup && ( (userData && (userData.roleName == 'user' || userData.roleName == 'screen_manager') || !token)) && !displayScreenPath && width ? this.renderPopup() : (
            <>
             {WebRoutesGenerator({ routeMap })}
             <ModalContainer />
            </>
          )
        }
      </div>
    );
  }
}


export default App;